import React from 'react';
import { Spin } from 'antd';

export default function LoadingScreen() {
  return (
    // Implement a loading screen
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin size={'large'} />
    </div>
  );
}
