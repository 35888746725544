import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from 'App';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import 'index.css';
import 'overrides.scss';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
const history = createBrowserHistory();

root.render(
  <Router history={history}>
    <App />
  </Router>,
);
