import { MenuProps } from 'antd';
import React from 'react';

import { ReactComponent as BellIcon } from 'assets/icons/Bell.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/Phone.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/PersonFill.svg';

export type MenuItem = Required<MenuProps>['items'][number];

export const topNavigationItems = [
  {
    key: '/',
    label: 'Actualités',
  },
  {
    key: '/dossiers',
    label: 'Suivi des dossiers',
  },
  {
    key: '/faq',
    label: 'FAQ',
  },
  {
    key: 'contact',
    icon: <PhoneIcon />,
  },
  {
    key: 'userAccount',
    icon: <PersonIcon />,
    children: [
      {
        key: '/mon-compte',
        label: 'Mon compte',
      },
      {
        key: 'logout',
        label: 'Déconnexion',
      },
    ],
  },
  {
    key: 'notifications',
    icon: <BellIcon />,
  },
];

export const bottomNavigationItems = [
  {
    key: '/',
    label: 'Actualités',
  },
  {
    key: '/soumettre-dossier',
    label: 'Soumission de dossier',
  },
  {
    key: '/dossiers',
    label: 'Suivi des dossiers',
  },
  {
    key: 'https://www.ecologie.gouv.fr/dispositif-des-certificats-deconomies-denergie',
    label: 'Réglementation',
    target: '_blank',
  },
  {
    key: '/faq',
    label: 'FAQ',
  },
  {
    key: '/mentions-legales',
    label: 'Mentions légales',
  },
  {
    key: '/conditions-generales',
    label: "Conditions générales d'utilisation",
  },
  {
    key: '/politique-de-confidentialite',
    label: 'Politique de confidentialité',
  },
];

export default topNavigationItems;
