import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';

function AppGuard(props: any) {
  const { children } = props;

  const { auth } = useAuthContext();
  if (!auth?.token) {
    return <Redirect to="/connexion" />;
  }

  return children;
}

export default AppGuard;
